@import url("https://fonts.cdnfonts.com/css/euclid-circular-a");

.info_container {
    width: 404px;
    height: 343px;
    right: 677px;
    top: 338px;
    align-items: center;

  background: #fff;
  border: 1.11698px solid #eaeaea;
  border-radius: 9.57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info_heading{
    font-family: 'Euclid Circular A';
font-style: normal;
font-weight: 400;
font-size: 20.3532px;
line-height: 26px;
/* identical to box height */
justify-content: center;

display: flex;
align-items: flex-end;
letter-spacing: -0.01em;
margin-bottom: 4%;

color: #202124;

}

.info_para {
  width: 294.96px;
  height: 63px;
  left: 730px;
  top: 541.97px;
  justify-content: center;

  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14.4px;
  line-height: 21px;
  /* or 146% */

  text-align: center;
  font-feature-settings: "liga" off;
  margin: 0 auto;
  color: #8a8a8a;
}

.info_link{
    color: #3E5EA9;
    text-decoration: underline;
    font-style: normal;
font-weight: 400;
font-size: 14.4px;
line-height: 21px;
/* or 146% */
}

.P_div {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.logo_div{
    display: flex;
    justify-content: center;
    margin-bottom: 8%;
}



@media (max-width: 700px) {
    .info_container {
        width: 100%;
    }
  }


  .landing_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100vh;
    width: 100%;
  }